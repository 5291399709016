/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'bag-heart': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M10.5 3.5a2.5 2.5 0 00-5 0V4h5zm1 0V4H15v10a2 2 0 01-2 2H3a2 2 0 01-2-2V4h3.5v-.5a3.5 3.5 0 117 0M14 14V5H2v9a1 1 0 001 1h10a1 1 0 001-1M8 7.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132"/>',
    },
});
